import { login, getInfo } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    phone: '',
    avatar: '',
    userInfo:{}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
     const phone = userInfo.phone.trim()
     const code = userInfo.code
     const tenantId = userInfo.tenantId
     return new Promise((resolve, reject) => {
       login(phone, code, tenantId).then(res => {
         setToken(res.data)
         commit('SET_TOKEN', res.data)
         resolve()
       }).catch(error => {
         reject(error)
       })
     })
   },

   // 获取用户信息
   GetInfo({ commit, state }) {
     return new Promise((resolve, reject) => {
       getInfo().then(res => {
          const data = res.data
          data.etpCustomerAdd = []
          data.etpCustomerAdd[0] = data.province//省
          data.etpCustomerAdd[1] = data.city//市
          data.etpCustomerAdd[2] = data.area//区
          const avatar = (data.avatar == "" || data.avatar == null) ? require("@/assets/avatar.png") : data.avatar;
          localStorage.setItem('resultState',data.state)
          commit('SET_PHONE', data.loginPhone)
          commit('SET_AVATAR', avatar)
          commit('SET_USERINFO', data)
          resolve(res)
       }).catch(error => {
         reject(error)
       })
     })
   },
   // 前端 登出
   FedLogOut({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
 },
}

export default user
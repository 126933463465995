<template>
  <div class="layout">
    <Headers @headLogin="onLogin"></Headers>
    <main>
      <router-view />
      <ContactUs ref="ContactUs"></ContactUs>
    </main>
    <Footers v-if="isShowFooter"></Footers>
  </div>
</template>
<script>
import Headers from '@/components/Headers/index.vue';
import Footers from '@/components/Footers/index.vue'
import ContactUs from "@/components/ContactUs"
export default {
  name:"Layout",
  components:{ Headers, Footers, ContactUs },
  data(){
    return{
      routerData:['/login','/result'],//忽略名单
    }
  },
  methods:{
    onLogin(){
      this.$refs.ContactUs.show()
    }
  },
  computed:{
    isShowFooter(){
      if(this.routerData.indexOf(this.$route.path) !== -1){
        return false
      }else{
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.layout{
  width: 100%;
  overflow: hidden;
  main{
    min-height: calc( 100vh - 390px - 67px );
    width: 100%;
    margin: auto;
    background: #f2f2f2;
  }
}
</style>
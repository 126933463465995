<template>
  <div class="ContactUs">
    <!-- 联系我们 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="title">联系我们</div>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input type="text" v-model="ruleForm.enterpriseName" placeholder="请输入真实企业名称" ><span slot="prefix" class="icon iconfont icon-qiye"></span></el-input>
        </el-form-item>
        <el-form-item  label="联系人" prop="principalName">
          <el-input type="text" v-model="ruleForm.principalName" placeholder="请输入姓名" ><span slot="prefix" class="icon iconfont icon-lianxiren"></span></el-input>
        </el-form-item>
        <el-form-item  label="联系电话" prop="phone">
          <el-input type="text" v-model="ruleForm.phone" placeholder="请输入真实手机号" ><span slot="prefix" class="icon iconfont icon-shouji"></span></el-input>
        </el-form-item>
        <el-form-item  label="所属行业">
          <el-input type="text" v-model="ruleForm.vocation" placeholder="请输入所属行业" ><span slot="prefix" class="icon iconfont icon-hangye"></span></el-input>
        </el-form-item>
        <el-form-item  label="所在地区">
          <el-input type="text" v-model="ruleForm.addr" placeholder="请输入所在地区" ><span slot="prefix" class="icon iconfont icon-weizhi"></span></el-input>
        </el-form-item>
        
        <el-form-item>
          <div style="width:100%;display:flex;">
            <el-button v-debounce type="primary" @click="submitForm('ruleForm')" class="submit">立即提交</el-button>
          </div>
        </el-form-item>
      </el-form>
      
    </el-dialog>
  </div>
</template>
<script>
  import { contact } from "@/api/index"
  export default{
    name:"Login",
    data(){
      return{
        dialogVisible: false,
        ruleForm: {
          
        },
        rules: {
          enterpriseName: [
            { required: true, message: '请输入企业名称', trigger: ['blur','change'] },
          ],
          principalName: [
            { required: true, message: '请输入联系人', trigger: ['blur','change'] },
          ],
          phone: [
            { required: true, message: "请输入联系电话", trigger: ['blur','change'] },
            { pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: "请输入合法手机号", trigger: "blur" }
          ],
        }
      }
    },
    methods:{
      show(){
        this.dialogVisible = true
      },
      handleClose(done) {
        done();
        this.ruleForm = {}
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            contact(this.ruleForm).then(res => {
              this.$modal.msgSuccess("提交成功");
              this.dialogVisible = false
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    }
  }
</script>
<style lang="scss" scoped>
.ContactUs{
  .title{
    width: 100%;
    height: 215px;
    background: url(@/assets/images/home/top.png) no-repeat;
    background-size: 100% 100%;
    font-size: 40px;
    text-align: center;
    color: #fff;
    padding-top: 44px;
    
  }
  .demo-ruleForm{
    padding: 0 100px;
    margin-top: -50px;
    .el-form-item{
      margin-bottom: 12px;
    }
  }
  .submit{
    width: 191px;
    height: 48px;
    margin: 20px auto 27px;
    font-size: 20px;
  }
}
::v-deep .el-dialog{
  border-radius: 10px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body{
    padding: 0;
    
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close{
  color: #fff;
}
::v-deep .el-input__prefix{
  left: 9px;
}
</style>
import router from './router'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'
import store from './store'


console.log(store,11111111)
//需要登录才能访问的路由地址
const whiteList = ['/joinus']
//路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (getToken()) {
    if(store.state.user.phone){
      next()
    }else{
      store.dispatch('GetInfo').then(() => {
        next()
      }).catch(err => {
        store.dispatch('FedLogOut').then(() => {
          Message.error(err)
          next({ path: '/' })
        })
      })
    }
    
  } else {
    if(whiteList.indexOf(to.path) !== -1){
      next(`/login?redirect=${to.fullPath}`)
    }else{
      next()
    }
  }
})




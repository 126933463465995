<template>
  <div class="Headers">
    <header>
      <div class="logo" @click="logoHandle"><img src="../../assets/logo.png" alt=""></div>
      <el-menu
        router
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        text-color="#666"
        active-text-color="#CD8B2E">
        <el-menu-item index="/home">首页</el-menu-item>
        <el-menu-item index="/service">产品与服务</el-menu-item>
        <el-menu-item index="/news">新闻资讯</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/joinus">加入我们</el-menu-item>
      </el-menu>
      <div class="account_wrap">
        <div v-if="isShowUser" class="user_info">
          <div class="avatar">
            <img src="@/assets/avatar.png" alt="">
          </div>
          <el-divider direction="vertical"></el-divider>
          <el-dropdown placement="bottom">
            <div class="phone">150****1695</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="onlogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          
        </div>
        
        <el-button v-else type="primary" style="color:#fff;margin:0 20px;" @click.native="onLogin">联系我们</el-button>
      </div>
    </header>
  </div>
</template>
<script>
import { getToken } from '@/utils/auth';
export default {
  name:"Headers",
  data() {
    return {

    };
  },
  computed:{
    isShowUser(){
      return this.$store.state.user.phone?true:false
    },
    phoneData(){
      if(this.isShowUser){
        var phone = this.$store.state.user.phone
        return phone.substring(0, 3)+"****"+phone.substr(phone.length-4);
      }
    }
  },
  created(){
    console.log(this.$store.state.user,111)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    onLogin(){
      this.$emit('headLogin')
    },
    logoHandle(){
      this.$router.push('/home')
    },
    //退出登录
    onlogout(){
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.clear()
        location.reload()
      }).catch(() => {});
    }
  }
}
</script>
<style lang="scss" scoped>
.Headers{
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0,52,168,0.20);
  border-bottom: 1px solid #eee;
  header{
    width: 1440px;
    height: 66px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 120px;
    box-sizing: border-box;
    .logo{
      width: 130px;
      height: 38px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .account_wrap{
      display: flex;
      align-items: center;
      .user_info{
        display: flex;
        align-items: center;
        .avatar{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #CD8B2E;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 80%;
            height: 80%;
            display: block;
          }
        }
        .phone{
          font-size: 15px;
          color: #666;
          cursor: pointer;
        }
      }
      
    }
  }
  .el-menu{
    max-width: 1440px;
    min-width: 300px;
    .el-menu-item{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .el-menu.el-menu--horizontal{
    border-bottom: 0;
  }
  
}
</style>